<template>
  <b-card class="blog-edit-wrapper">
    <div class="text-center">
      <h4 class="mt-5">
        Add Classical Course Price
        <feather-icon icon="Edit2Icon" />
      </h4>
    </div>
    <!-- form -->
    <validation-observer ref="addCourseFormvalidate">
      <b-form
        class="mt-2"
        style="width: 100%"
        @submit.prevent="save"
        ref="form">
        <b-row>
          <b-col md="4">
            <b-form-group
              label="Category"
              label-for="blog-edit-category"
              class="mb-2">
              <validation-provider
                #default="{ errors }"
                name="Category"
                rules="required">
                <v-select
                data-testid="course-classical-category"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  v-model="addCourseForm.category_id"
                  label="type"
                  :options="categoriesList"
                  :reduce="(val) => val.id"
                  @input="getCoursesByCategory(addCourseForm.category_id)" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Course"
              label-for="blog-edit-category"
              class="mb-2">
              <validation-provider #default="{ errors }" name="Course" rules="required">
                <v-select
                data-testid="course-classical-name"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  v-model="addCourseForm.course_id"
                  label="name"
                  :options="coursesList"
                  :reduce="(val) => val.id">
                  <template #no-options="{ search, searching, loading }" v-if="!getCourse">
                    Select Category First ...
                   </template>
                   <template #no-options="{ search, searching, loading }" v-else>
                    Loading ...
                   </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label="City"
              label-for="blog-edit-category"
              class="mb-2">
              <validation-provider #default="{ errors }" name="City" rules="required">
                <v-select
                data-testid="course-classical-city"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  v-model="addCourseForm.city_id"
                  label="name"
                  :options="citiesList"
                  :reduce="(val) => val.id" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Price" label-for="blog-edit-slug" class="mb-2">
              <validation-provider
                #default="{ errors }"
                name="price"
                rules="required">
                <b-form-input
                data-testid="course-classical-price"
                  type="number"
                  v-model="addCourseForm.price" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12" class="mt-50">
            <b-button
            data-testid="course-submit-button"
              v-if="!isLoading"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save">
              Save Changes
            </b-button>

            <b-button v-if="isLoading" variant="primary" disabled class="mr-1">
              Loading...
              <b-spinner small />
              <span class="sr-only"></span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              @click="reset"
              variant="outline-secondary">
              Reset
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BFormRadio,
  BFormCheckbox,
  BFormRadioGroup,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BCardTitle,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import { quillEditor } from "vue-quill-editor";
import { required, email } from "@validations";
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref, reactive } from "@vue/composition-api";
import store from "@/store";
import Vue from "vue";
export default {
  setup(props, { emit }) {
    // const store = useStore();
    const refInputEl = ref(null);
    const refInputE2 = ref(null);
    const refPreviewEl = ref(null);
    const refPreviewE2 = ref(null);
    const Tabimage = ref("");
    const categoriesList = ref([]);
    const coursesList = ref([]);
    const isLoading = ref(false);
    const citiesList = ref([]);

    const getCourse=ref(false)
    const getCoursesByCategory = (id) => {
      addCourseForm.course_id = "";
      getCourse.value=true
      
      store
        .dispatch("courses/getAllCoursesList", {
          "filter[category_id]": id,
          "filter[online]":0
        })
        .then((response) => {
         
          coursesList.value = response.data;
        });
    };

    store.dispatch("cities/cititesList").then((response) => {
      citiesList.value = response.data;
    });

    store.dispatch("categories/AllCategory").then((response) => {
      
      categoriesList.value = response.data.data;
    });
    const addCourseForm = reactive({
      price: "",
      course_id: "",
      city_id: "",

      category_id: "",
    });
    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      (base64) => {
        refPreviewEl.value.src = base64;
      }
    );
    const { inputImageRendererTab } = useInputImageRenderer(
      refInputE2,
      (base64) => {
        refPreviewE2.value.src = base64;
      }
    );
    const reset=()=>{
      addCourseForm.course_id=''
          addCourseForm.city_id=''
          addCourseForm.category_id=''
          addCourseForm.price=''
    }
    const addCourseFormvalidate = ref();
    const form = ref();
    const save = () => {
      ;
      const formData = new FormData();

      addCourseFormvalidate.value.validate().then((success) => {
        if (success) {
          formData.append("price", addCourseForm.price);
          formData.append("course_id", addCourseForm.course_id);
          formData.append("city_id", addCourseForm.city_id);
          formData.append("online", 0);

          formData.append("category_id", addCourseForm.category_id);
         //   console.log("formData", formData);
         
          isLoading.value = true;
          store
            .dispatch("price/addPrice", formData)
            .then((response) => {
              addCourseForm.course_id=''
          addCourseForm.city_id=''
          addCourseForm.category_id=''
              emit("add-price");

              form.value.reset();
              isLoading.value = false;
              Vue.swal({
                title: "Price Added ",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            })
            .catch((error) => {
              isLoading.value = false;
              Vue.swal({
                title: "",
                text: `${error.response.data.message}`,
                icon: "error",
                confirmButtonText: "ok",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });

         
        }
      });
    };
    return {
      refInputEl,
      refPreviewEl,
      refInputE2,
      refPreviewE2,
      inputImageRendererTab,
      Tabimage,
      addCourseForm,
      inputImageRenderer,
      getCoursesByCategory,
      addCourseFormvalidate,
      categoriesList,
      coursesList,
      citiesList,
      form,
      isLoading,
      save,
      required,
      getCourse,
      email,
      reset
    };
  },

  components: {
    BCard,
    BMedia,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BMediaAside,
    BMediaBody,
    BFormRadio,
    BFormCheckbox,
    BFormRadioGroup,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    quillEditor,
    BCardTitle,
  },

  directives: {
    Ripple,
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
