var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"blog-edit-wrapper"},[_c('div',{staticClass:"text-center"},[_c('h4',{staticClass:"mt-5"},[_vm._v(" Code Generator "),_c('feather-icon',{attrs:{"icon":"Edit2Icon"}})],1)]),_c('validation-observer',{ref:"addCourseFormvalidate"},[_c('b-form',{staticClass:"mt-2",staticStyle:{"width":"100%"},on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Category","label-for":"blog-edit-category"}},[_c('validation-provider',{attrs:{"name":"Category","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"data-testid":"category-code-select","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"type","options":_vm.categoriesList,"reduce":function (val) { return val.id; }},on:{"input":function($event){return _vm.getCoursesByCategory(_vm.addCourseForm.category_id)}},model:{value:(_vm.addCourseForm.category_id),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "category_id", $$v)},expression:"addCourseForm.category_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Course","label-for":"blog-edit-category"}},[_c('validation-provider',{attrs:{"name":"Course","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"course-code-select","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"name","options":_vm.coursesList,"reduce":function (val) { return val.id; }},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_vm._v(" No data ... ")]}}],null,true),model:{value:(_vm.addCourseForm.course_id),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "course_id", $$v)},expression:"addCourseForm.course_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Discount","label-for":"blog-content"}},[_c('validation-provider',{attrs:{"name":"Description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"data-testid":"course-code-discount","type":"number"},model:{value:(_vm.addCourseForm.discount),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "discount", $$v)},expression:"addCourseForm.discount"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Users Number","label-for":"blog-edit-course"}},[_c('validation-provider',{attrs:{"name":"Courses","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"data-testid":"code-users-number","type":"number"},model:{value:(_vm.addCourseForm.users),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "users", $$v)},expression:"addCourseForm.users"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"email","label-for":"blog-content"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"data-testid":"code-user-email"},model:{value:(_vm.addCourseForm.email),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "email", $$v)},expression:"addCourseForm.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Company Name","label-for":"blog-edit-course"}},[_c('validation-provider',{attrs:{"name":"Courses","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"data-testid":"code-company-name"},model:{value:(_vm.addCourseForm.companyName),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "companyName", $$v)},expression:"addCourseForm.companyName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Start Date","label-for":"blog-edit-course"}},[_c('validation-provider',{attrs:{"name":"Courses","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{staticClass:"mb-1",attrs:{"data-testid":"code-start-date"},model:{value:(_vm.addCourseForm.expire_date),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "expire_date", $$v)},expression:"addCourseForm.expire_date"}})]}}])})],1)],1),_c('b-col',{staticClass:"mt-50",attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"data-testid":"course-submit-button","variant":"primary"},on:{"click":_vm.save}},[_vm._v(" GENERATE CODE ")])],1)],1)],1)],1),_c('b-row',[_c('GenertCodeList',{ref:"child",staticStyle:{"width":"100%"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }